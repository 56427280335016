import FeatureProduct from "./FeatureProduct";
import ScrollToTopOnMount from "../template/ScrollToTopOnMount";
import  Data  from "../Data";
function Landing() {
  return (
    <>
      <ScrollToTopOnMount />
      <h2 className="text-muted text-center mt-4 mb-3"></h2>
      <div className="container pb-5 px-lg-5">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 px-md-5">
        {Data.map((item) => (
          <FeatureProduct item={item} />
        ))}
        </div>
      </div>
      <div className="d-flex flex-column bg-white py-4">

      </div>
    </>
  );
}

export default Landing;
