import Image from "../nillkin-case.webp";
import { Link } from "react-router-dom";

function FeatureProduct({item}) {
  return (
    <div className="col pt-5">
      <div className="card shadow-sm">
        <img
          className="card-img-top bg-dark cover"
          height="240"
          alt=""
          src={item.image2.default}
        />
        <div className="card-body">
          <h5 className="card-title text-center">{item.name}</h5>
          <div className="d-grid gap-2">
            <Link to={`/products/${item.id}`} className="btn btn-outline-dark" replace>
              Detalle
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureProduct;
