import Image from "../../nillkin-case-1.jpg";
import { Link,useParams } from "react-router-dom";
import ScrollToTopOnMount from "../../template/ScrollToTopOnMount";
import  Data  from "../../Data";

const iconPath =
  "M18.571 7.221c0 0.201-0.145 0.391-0.29 0.536l-4.051 3.951 0.96 5.58c0.011 0.078 0.011 0.145 0.011 0.223 0 0.29-0.134 0.558-0.458 0.558-0.156 0-0.313-0.056-0.446-0.134l-5.011-2.634-5.011 2.634c-0.145 0.078-0.29 0.134-0.446 0.134-0.324 0-0.469-0.268-0.469-0.558 0-0.078 0.011-0.145 0.022-0.223l0.96-5.58-4.063-3.951c-0.134-0.145-0.279-0.335-0.279-0.536 0-0.335 0.346-0.469 0.625-0.513l5.603-0.815 2.511-5.078c0.1-0.212 0.29-0.458 0.547-0.458s0.446 0.246 0.547 0.458l2.511 5.078 5.603 0.815c0.268 0.045 0.625 0.179 0.625 0.513z";

function ProductDetail() {
  let { id } = useParams();
    return (
      <div className="container mt-5 py-4 px-xl-5">
        {Data.filter(function (data) {
          return data.id === id;
          }).map((item) => (
            <div>
              <ScrollToTopOnMount/>
              <nav aria-label="breadcrumb" className="bg-custom-light rounded mb-4">
                <ol className="breadcrumb p-3">
                  <li className="breadcrumb-item">
                    <Link className="text-decoration-none link-secondary" to="/">
                      Inicio
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {item.name}
                  </li>
                </ol>
              </nav>
              <div className="row mb-4">
                <div className="d-none d-lg-block col-lg-1">
                  <div className="image-vertical-scroller">
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <img
                        className="border rounded ratio ratio-1x1"
                        alt=""
                        src={item.image2.default}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-5">
                  <div className="d-flex flex-column h-100">
                    <h2 className="mb-1">{item.name}</h2>
                    <hr />
                    <dl className="row">
                      <dt className="col-sm-4">Código</dt>
                      <dd className="col-sm-8 mb-3">{item.code}</dd>

                      <dt className="col-sm-4">Cantidad</dt>
                      <dd className="col-sm-8 mb-3">{item.cantidad}</dd>

                      <dt className="col-sm-4">Status</dt>
                      <dd className="col-sm-8 mb-3">En stock</dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-4">
                  <hr />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-4">
                  <div className="col pt-5">
                    <div className="card shadow-sm">
                      <img
                        className="card-img-top bg-dark cover"
                        height="500"
                        width="auto"
                        alt=""
                        src={item.image2.default}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="col pt-5">
                    <div className="card shadow-sm">
                      <img
                        className="card-img-top bg-dark cover"
                        height="500"
                        width="auto"
                        alt=""
                        src={item.image3.default}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="col pt-5">
                    <div className="card shadow-sm">
                      <img
                        className="card-img-top bg-dark cover"
                        height="500"
                        width="auto"
                        alt=""
                        src={item.image4.default}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        ))}
      </div>
  );
}

export default ProductDetail;
