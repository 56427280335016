const ProductData = [
    {
       "id":"1",
       "name":"Dinosaur Egg",
       "code":"001",
       "cantidad":"12 Unidades",
       "brand":"",
       "image1": require("./images/pro1/image1pro1.jpeg"),
       "image2": require("./images/pro1/image2pro1.jpeg"),
       "image3": require("./images/pro1/image3pro1.jpeg"),
       "image4": require("./images/pro1/image4pro1.jpeg")
    },
    {
        "id":"2",
        "name":"Laynier",
        "code":"002",
        "cantidad":"12 Unidades",
        "brand":"",
        "image1": require("./images/pro2/image1pro2.jpeg"),
        "image2": require("./images/pro2/image2pro2.jpeg"),
        "image3": require("./images/pro2/image3pro2.jpeg"),
        "image4": require("./images/pro2/image4pro2.jpeg")
     },
     {
        "id":"3",
        "name":"Unicorn",
        "code":"003",
        "cantidad":"20 Unidades",
        "brand":"",
        "image1": require("./images/pro3/image1pro3.jpeg"),
        "image2": require("./images/pro3/image2pro3.jpeg"),
        "image3": require("./images/pro3/image3pro3.jpeg"),
        "image4": require("./images/pro3/image4pro3.jpeg")
     },
     {
      "id":"4",
      "name":"Mini Pull-Back Cars",
      "code":"004",
      "cantidad":"24 Unidades",
      "brand":"",
      "image1": require("./images/pro4/image1pro4.jpeg"),
      "image2": require("./images/pro4/image2pro4.jpeg"),
      "image3": require("./images/pro4/image3pro4.jpeg"),
      "image4": require("./images/pro4/image1pro4.jpeg")
   },
   {
      "id":"5",
      "name":"Dinosalirs DRFormation Egg",
      "code":"005",
      "cantidad":"12 Unidades",
      "brand":"",
      "image1": require("./images/pro5/image1pro5.jpeg"),
      "image2": require("./images/pro5/image2pro5.jpeg"),
      "image3": require("./images/pro5/image3pro5.jpeg"),
      "image4": require("./images/pro5/image1pro5.jpeg")
   },
   {
      "id":"6",
      "name":"Bubbles Dinosaur",
      "code":"006",
      "cantidad":"12 Unidades",
      "brand":"",
      "image1": require("./images/pro6/image1pro6.jpeg"),
      "image2": require("./images/pro6/image2pro6.jpeg"),
      "image3": require("./images/pro6/image1pro6.jpeg"),
      "image4": require("./images/pro6/image2pro6.jpeg")
   },
   {
      "id":"7",
      "name":"Shark Pull Back Climbing car",
      "code":"007",
      "cantidad":"6 Unidades",
      "brand":"",
      "image1": require("./images/pro7/image1pro7.jpeg"),
      "image2": require("./images/pro7/image2pro7.jpeg"),
      "image3": require("./images/pro7/image3pro7.jpeg"),
      "image4": require("./images/pro7/image4pro7.jpeg")
   },
   {
      "id":"8",
      "name":"Fashion Eggs",
      "code":"008",
      "cantidad":"24 Unidades",
      "brand":"",
      "image1": require("./images/pro8/img1pro8.jpeg"),
      "image2": require("./images/pro8/img2pro8.jpeg"),
      "image3": require("./images/pro8/img3pro8.jpeg"),
      "image4": require("./images/pro8/img1pro8.jpeg")
   },
   {
      "id":"9",
      "name":"Laynier Scented pocket doll",
      "code":"009",
      "cantidad":"12 Unidades",
      "brand":"",
      "image1": require("./images/pro9/img1pro9.jpeg"),
      "image2": require("./images/pro9/img2pro9.jpeg"),
      "image3": require("./images/pro9/img3pro9.jpeg"),
      "image4": require("./images/pro9/img1pro9.jpeg")
   },
 ];
 export default ProductData